import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ToggleButton from 'components/ToggleButton'
import { hideAll } from 'reports/hourlyScrap/actions'
import { compose } from 'redux'
import { injectIntl, intlShape } from 'react-intl'
import { selectAllHidden } from './selectors'

const HourlyScrapLocationSelectAll = ({
  allHidden,
  hideAll,
  intl
}) => (
  <ToggleButton
    isToggled={allHidden}
    onToggleOn={hideAll}
    onToggleOff={hideAll}
    color='default'
  >
    {intl.formatMessage({ id: 'components.button.hideAll' })}
  </ToggleButton>
)

HourlyScrapLocationSelectAll.propTypes = {
  allHidden: PropTypes.bool,
  hideAll: PropTypes.func,
  intl: intlShape
}

const mapStateToProps = state => ({
  allHidden: selectAllHidden(state)
})

const mapDispatchToProps = {
  hideAll: hideAll
}

export default compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(HourlyScrapLocationSelectAll)
