import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

const HourlyScrapReportGraphTooltip = ({ payload, label, ...rest }) => {
  if (!payload) {
    return null
  }

  const [quantity] = payload

  if (!label || !quantity) {
    return null
  }

  return (
    <div className='report-graph-tooltip'>
      <div className='report-graph-tooltip__content'>
        <h4 className='report-graph-tooltip__header'>{label}</h4>
      </div>

      <div className='report-graph-tooltip__footer'>
        <FormattedMessage id='workspace.scrap.quantity' defaultMessage='Quantity' />
        {': '}
        {quantity.value}
      </div>
    </div>
  )
}

HourlyScrapReportGraphTooltip.propTypes = {
  label: PropTypes.string,
  payload: PropTypes.array
}

export default HourlyScrapReportGraphTooltip
