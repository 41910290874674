import React from 'react'
import PropTypes from 'prop-types'

const HourlyScrapReportGraphLabel = ({ x, y, stroke, payload, ...rest }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={8} textAnchor='start' fill='#666' transform='rotate(66)'>
        {payload.value}
      </text>
    </g>
  )
}

HourlyScrapReportGraphLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  stroke: PropTypes.string,
  payload: PropTypes.shape({
    value: PropTypes.string.isRequired
  })
}

export default HourlyScrapReportGraphLabel
