import { connect } from 'react-redux'
import { selectReports } from 'reports/hourlyScrap/selectors'
import { ResponsiveContainer, ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'
import React from 'react'
import PropTypes from 'prop-types'

import HourlyScrapReportGraphTooltip from './HourlyScrapReportGraphTooltip'
import HourlyScrapReportGraphLabel from './HourlyScrapReportGraphLabel'

const HourlyScrapReportGraph = ({ data, dataKey, GraphLabel, GraphTooltip }) => {
  return (
    <div style={{ width: '100%', height: 500, marginTop: 18, marginBottom: 18 }}>
      <ResponsiveContainer>
        <ComposedChart width={600} height={400} data={data} margin={{ top: 20, right: 20, bottom: 120, left: 20 }}>
          <CartesianGrid stroke='#f5f5f5' />
          <XAxis
            dataKey='timestamp'
            interval={0}
            tick={<GraphLabel />}
          />
          <YAxis
            dataKey={dataKey}
            tickCount={11}
          />
          <Tooltip content={<GraphTooltip />} />
          <Bar
            dataKey={dataKey}
            barSize={40}
            fill='#00BB5E'
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}

HourlyScrapReportGraph.propTypes = {
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string.isRequired,
  GraphLabel: PropTypes.func.isRequired,
  GraphTooltip: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  data: selectReports(state),
  dataKey: 'quantity',
  GraphLabel: HourlyScrapReportGraphLabel,
  GraphTooltip: HourlyScrapReportGraphTooltip
})

export default connect(mapStateToProps)(HourlyScrapReportGraph)
