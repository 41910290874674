import { connect } from 'react-redux'
import { selectLocationIds } from 'reports/hourlyScrap/selectors'
import HourlyScrapReportLocationToggle from 'reports/hourlyScrap/HourlyScrapReportLocationToggle'
import ProductionReportLocations from 'components/ProductionReportLocations'
import HourlyScrapLocationSelectNone from './HourlyScrapLocationSelectNone'
import HourlyScrapLocationSelectAll from './HourlyScrapLocationSelectAll'

const mapStateToProps = (state) => ({
  ids: selectLocationIds(state),
  Toggle: HourlyScrapReportLocationToggle,
  SelectAll: HourlyScrapLocationSelectAll,
  SelectNone: HourlyScrapLocationSelectNone
})

export default connect(mapStateToProps)(ProductionReportLocations)
