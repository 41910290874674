import { createSelector } from 'reselect'

export const selectToken = state => state.config.token
export const selectTimezone = state => state.config.timezone
export const selectPlant = state => state.plant
export const selectNavigation = state => state.navigation
export const selectNavigationScope = state => state.navigation.scope
export const selectNavigationDate = state => state.navigation.date
export const selectNavigationShift = state => state.navigation.shift
export const selectLocationIds = state => state.locations.allIds
export const selectLocation = (state, id) => state.locations.allById[id]
export const selectVisibleLocationIds = state => state.locations.allIds.filter(id => state.locations.isVisibleById[id])
export const selectIsLocationVisible = (state, id) => state.locations.isVisibleById[id]
export const selectReports = (state) => (
  state
    .reports
    .allIds
    .map(id => state.reports.allById[id])
)

export const selectAllSelected = createSelector(
  selectLocationIds,
  selectVisibleLocationIds,
  (allIds, selectedIds) => allIds.sort().join(',') === selectedIds.sort().join(',')
)

export const selectAllHidden = createSelector(
  selectVisibleLocationIds,
  selectedIds => selectedIds.length === 0
)