import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ToggleButton from 'components/ToggleButton'
import { showAll } from 'reports/hourlyScrap/actions'
import { compose } from 'redux'
import { injectIntl, intlShape } from 'react-intl'
import { selectAllSelected } from './selectors'

const HourlyScrapLocationSelectAll = ({
  allSelected,
  showAll,
  intl
}) => (
  <ToggleButton
    isToggled={allSelected}
    onToggleOn={showAll}
    onToggleOff={showAll}
    color='default'
  >
    {intl.formatMessage({ id: 'components.button.showAll' })}
  </ToggleButton>
)

HourlyScrapLocationSelectAll.propTypes = {
  allSelected: PropTypes.bool,
  showAll: PropTypes.func,
  intl: intlShape
}

const mapStateToProps = state => ({
  allSelected: selectAllSelected(state)
})

const mapDispatchToProps = {
  showAll: showAll
}

export default compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(HourlyScrapLocationSelectAll)
